import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const baseUrl = '/api/controltower/takeUpReturnsAndClaims'

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.saiCode.onValueChange = (value, fields) => {
    const fieldId = fields.id.value
    let valueCurrent = fields.saiCode.value
    const status = fields.status.value?.value
    if (status === 'approved') {
      fields.quantity.disabled = true
      fields.saiCode.disabled = true
      fields.type.disabled = true
      fields.observation.disabled = true
    }
    if (!fieldId > 0) {
      fields.status.disabled = true
      if (valueCurrent) {
        fields.quantity.disabled = true
        fields.quantity.value = 1
      } else {
        fields.quantity.disabled = false
        fields.quantity.value = null
      }
    } else {
      if (!fields.saiCode.disabled) {
        fields.quantity.disabled = true
      }
    }
  }

}

export default service
