import {
  api,
  createCrudService,
  responseToOptions,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

const logRequest = (serviceName, commandName, ...args) => {
  // eslint-disable-next-line no-console
  console.log(
    `%c ${serviceName} %c ${commandName} %c`,
    'background:#21a0e2 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
    'background:#89cd4d ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent',
    ...args
  )
}

const baseUrl = '/api/controltower/takeUpReturnsAndClaimsMaster'

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  Object.keys(ctx.farmOptionsByProducer).forEach((x) => {
    ctx.farmOptionsByProducer[x] = responseToOptions(
      ctx.farmOptionsByProducer[x]
    )
  })
  fieldsById.purchaseContract.options = ctx.purchaseContractOptions
  fieldsById.producerName.disabled = true
  fieldsById.producerName.onValueChange = (value, fields) => {
    fields.producerName.disabled = true
    // fields.currentQuantity.disabled = true
    // fields.allQuantity.disabled = true
  }

  fieldsById.saiCode.onValueChange = (value, fields) => {
    if (value) {
      fields.quantity.disabled = true
      fields.quantity.value = 1
    } else {
      fields.quantity.disabled = false
      fields.quantity.value = null
    }
  }

  fieldsById.purchaseContract.onValueChange = (changedValue, fields) => {
    const purchaseContractId = changedValue?.value
    const farmId = fields.farm.value?.value
    const producerId = ctx.producerIdByPurchaseContractId[purchaseContractId]
    fields.producerName.value = ctx.producerNameByProducerId[producerId]
    const farmOptions = ctx.farmOptionsByProducer[producerId] ?? []

    if (!farmOptions.some((x) => x.value == farmId)) {
      fields.farm.value = null
    }
  }

  fieldsById.farm.options = async ({ itemData }) => {
    const purchaseContractId = itemData.purchaseContract?.value
    const producerId = ctx.producerIdByPurchaseContractId[purchaseContractId]
    return ctx.farmOptionsByProducer[producerId] ?? []
  }
}
service.validateFieldsWithStatusChange = async (entities) => {
  logRequest('P/D', 'getReturnAndClaimDisapproval')
  try {
    const response = await api.post(
      `${baseUrl}/getReturnAndClaimDisapproval`,
      entities,
      {
        responseType: 'blob'
      }
    )

    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}
const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")

  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null

  return decodeURIComponent(filename)
}
export default service
