<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import {
  CustomCgCrudMetaView,
  helpers,
  responseToOptionsInContext,
  responseToOptions
} from '@cargill/shared'
import { CrudAction } from '@brain/core'
import service from '../api/takeUpReturnsAndClaimsService'
import serviceMaster from '../api/takeUpReturnsAndClaimsMaster'
import _ from 'lodash'
export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: serviceMaster,
      service,
      reloadData: false,
      details: {}
    }
  },
  methods: {
    async getTakeUpReturnsAndClaimsMeta() {
      const meta = await service.getMetaWithValidation()
      helpers.processMetaDetails(
        meta,
        'takeUpReturnsAndClaims',
        'takeUpReturnsAndClaimsMasterId',
        'takeUpReturnsAndClaimsMaster',
        this.service
      )
      return meta
    },
    async getMetaDetails() {
      return await Promise.all([this.getTakeUpReturnsAndClaimsMeta()])
    },
    async getMeta() {
      let [meta, metaDetails, ctx] = await Promise.all([
        serviceMaster.getMetaWithValidation(),
        this.getMetaDetails(),
        serviceMaster.getValidationContext()
      ])
      meta.details = metaDetails
      meta.id = 'takeUpReturnsAndClaimsMaster'

      responseToOptionsInContext(ctx)

      const fieldsById = _.keyBy(meta.fields, (f) => f.id)

      const getPurchaseContractOptions = (fields) => {
        var result = ctx.purchaseContractOptions.map((x) => x.label)
        if (fields.company.value != null) {
          var fieldCompany =
            typeof fields.company.value === 'object'
              ? fields.company.value.value
              : fields.company.value
          result = result.filter((x) => x.company == fieldCompany)
        }
        if (fields.cropYear.value != null) {
          result = result.filter((x) => x.cropYear == fields.cropYear.value)
        }
        result = result.map((x) => {
          return {
            label: x.contractReference,
            text: x.contractReference,
            value: x.id
          }
        })
        return responseToOptions(result)
      }

      const updatePurchaseContractOptions = (fields) => {
        const purchaseContractOptions = getPurchaseContractOptions(fields)

        helpers.updateOptionsObject(
          this,
          fieldsById.purchaseContract,
          fields.purchaseContract,
          purchaseContractOptions
        )
      }

      fieldsById.company.onValueChange = (changedValue, fields) =>
        updatePurchaseContractOptions(fields)

      fieldsById.cropYear.onValueChange = (changedValue, fields) =>
        updatePurchaseContractOptions(fields)

      return meta
    },
    reload() {
      this.reloadData = true
    },
    registerCrudProcessing() {
      service.registerPostProcessing(CrudAction.DELETE, this.reload)
    },
    cancelCrudProcessing() {
      service.cancelPostProcessing(CrudAction.DELETE, this.reload)
    }
  },
  beforeDestroy() {
    this.cancelCrudProcessing()
  },
  created() {
    this.registerCrudProcessing()
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
